import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'

import { Link } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'

import { Helmet } from 'react-helmet'


const iconStyles = {
  width:"1em",
  height:"1em"
}


const IndexPage = () => {

  return (

      <Layout>
        <SEO title="Nutniti Suteerapongpan | Marketer , Thailand" description='Nutniti Suteerapongpan is a Marketer. High level experience in Search Engine Optimization , Data Analytics and Information retrieval.' />
        <Helmet>
      
          <script type="application/ld+json">
          {`  {
            "@context":"https://schema.org",
            "@type":"Person",
            "description":"Nutniti Suteerapongpan is a marketer with experience in search engine marketing , data analytics, project management, and information retrieval.",
            "name":"Nutniti Suteerapongpan",
            "gender" :"Male",

            "url":"https://nutniti.com/",
            "sameAs":["https://www.facebook.com/nutniti.suteerapongpan","https://www.instagram.com/tee_nt/","https://twitter.com/nutniti1"],
            "image":"https://nutniti.com/static/0b361e1fa3f5ff19d20c3a8d4d60d99e/cdff5/nutniti.webp",
            "familyName":"Suteerapongpan",
            "givenName":"Nutniti",
            "jobTitle":"Internet Marketer",
            "birthDate":"1996-07-23",
            "birthPlace":{
              "@type":"City",
              "address":{
                "@type":"PostalAddress",
                "addressLocality":"Bangkok",
                "addressCountry":"TH"
                }
              },
            "nationality":{"@type":"Country","name":"Thailand"}}
            `}
            </script>






        </Helmet>

       

        
        <div class="row">
          <div class="col">
            <p>&nbsp;</p>
          <StaticImage  alt='nutniti suteerapongpan'
          src="../images/nutniti2.png" />
          

<h5 class='text-center'><img className="emoji" role="img" draggable="false" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f40d.svg" width='20' alt="🐍" /> Python&nbsp;</h5>
<h5 class='text-center'>🛒 Marketing&nbsp;</h5>
<h5 class='text-center'><img className="emoji" role="img" draggable="false" src="https://s.w.org/images/core/emoji/13.1.0/svg/1f1f9-1f1ed.svg" width='20'  alt="🇹🇭" />&nbsp;Bangkok, Thailand</h5>
<p>&nbsp;</p>     
<h4 class="fst-italic">Who is Nutniti Suteerapongpan ?</h4>
              <p class="mb-0">Nutniti Suteerapongpan is a marketer with experience in search engine marketing , data analytics, CRM, website development, project management, and information retrieval.</p>
              <p>&nbsp;</p>
              <h4 class="fst-italic">What can he do ?</h4>
              <p class="mb-0"><strong>Language</strong> : English , Thai</p>
              <p>&nbsp;</p>
<p class="mb-0"><strong>Computer Language</strong> : <strong>Python</strong> , HTML , CSS , Javascript ,SQL<strong><br /></strong></p>
<p>&nbsp;</p>
<p class="mb-0"><strong>Tools</strong> : Google Analytics ,Google Search Console , Google Tag Manager,
 		Ahref, Screaming Frog , Data Studio , SEMrush, Insider, Branch, PythonAnywhere, Algolia </p>
<p>&nbsp;</p>
<p class="mb-0"><strong>Digital Marketing</strong> : SEO , SEM , Press/ PR, App Install, CRM </p>
<p>&nbsp;</p>
<p class="mb-0"><strong>Experienced</strong> :</p>
<p>&nbsp;</p>
<p><strong>MAR 2023 - MAY 2023 :</strong> JENOSIZE Commerce Limited. ,Worked as a <strong>SEO Specialist </strong></p>
<p>&nbsp;</p>
<p><strong>SEP 2021 - FEB 2023 :</strong> Central JD Commerce Limited. ,Worked as a <strong>Marketing SEO Specialist </strong></p>
<p>&nbsp;</p>
<p><strong>JAN 2020 &ndash; SEP 2021 :</strong> The Business SEO Co. Ltd , Worked as a <strong>SEO account manager </strong></p>
<p>&nbsp;</p>
<p><strong>SEP 2019 &ndash; JAN 2020 :</strong> The Business SEO Co. Ltd, Worked as a <strong>Digital Marketing associate (SEO&amp;SEM)</strong></p>
<p>&nbsp;</p>
<p class="mb-0"><strong>Data Analytics</strong> : Information Retrieval , Natural Language , Data Visualization</p>
<p>&nbsp;</p>
<p class="mb-0"><strong>Interested in</strong> : Technology , Business , Marketing</p>     
<p>&nbsp;</p>
<h4 class="fst-italic">Quote, I like</h4>
<p>"Fake It Till You Make It"</p>

          </div>
          
          <div class="col-md-6">
            <div class="position-sticky">
              <div class="p-4 mb-3  rounded">

 

<a class="twitter-timeline" href="https://twitter.com/nutniti1?ref_src=twsrc%5Etfw">Tweets by nutniti1</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>

              </div>
            </div>
          </div>
        </div>
     
 
       


        <p>&nbsp;</p>
 
       

  <div>
  <a href="https://twitter.com/nutniti1?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @nutniti1</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
    </div>
 
  

   
      </Layout>
  )
}

export default IndexPage
